$(document).ready(function () {

  var carousel = $('.carouselSlioder')

  if (!carousel || !carousel.length) {
  } else {
    $.each(carousel, function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 1,
          infinite: false,
          appendArrows: $(this).next(),
          prevArrow: "<span class='slick-prev'>PREV</span>",
          nextArrow: "<span class='slick-next'>NEXT</span>"
        })
    });
  }
  var galleryCarousel = $('.carouselSlidergallery');

  if (!galleryCarousel || !galleryCarousel.length) {
  } else {
    $.each(galleryCarousel, function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 2,
          infinite: false,
          appendArrows: $(this).next(),
          prevArrow: "<span class='slick-prev'>PREV</span>",
          nextArrow: "<span class='slick-next'>NEXT</span>",
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                infinite: false
              }
            }
          ]
        })
    });
  }

  //GLAVA-293
  var articleSlider = $('.article-slider__wrapper');

  if(articleSlider && articleSlider.length) {
    articleSlider.not('.slick-initialized').slick({
      slidesToShow: 4,
      //swipe: false,
      swipeToSlide: false,
      infinite: false,
      prevArrow:
        `<button type="button" class="slick-arrow slick-prev">previous</button>`,
      nextArrow:
        `<button type="button" class="slick-arrow slick-next">next</button>`,
      responsive: [
        {
          breakpoint: 1350,
          swipeToSlide: false,
          settings: {
            slidesToShow: 3,
            infinite: false
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            infinite: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "70px",
            swipe: true,
            swipeToSlide: true,
            slidesToShow: 1,
            centerMode: true,
            //infinite: true,
          }
        }
      ]
    });
  }

  //GLAVA-361
  var carouselNews = $('.carousel-news__slick')

  if (!carouselNews || !carouselNews.length) {
  } else {
    $.each(carouselNews , function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          appendArrows: $(this).next(),
          prevArrow:
          `<button type="button" class="slick-arrow slick-prev">Previous</button>`,
          nextArrow:
          `<button type="button" class="slick-arrow slick-next">Next</button>`,
          responsive: [
            {
              breakpoint: 1350,
              swipeToSlide: true,
              settings: {
                slidesToShow: 3,
                infinite: false
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                infinite: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                swipe: true,
                swipeToSlide: true,
                slidesToShow: 1,
              }
            }
          ]
        })
    });
  }
  var carouselSquare = $('.square-card--slick .square-card__wrapp')

  if (!carouselSquare || !carouselSquare.length) {
  } else {
    $.each(carouselSquare , function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          prevArrow:
          `<button type="button" class="slick-arrow slick-prev">Previous</button>`,
          nextArrow:
          `<button type="button" class="slick-arrow slick-next">Next</button>`,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                infinite: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                swipe: true,
                swipeToSlide: true,
                slidesToShow: 1,
              }
            }
          ]
        })
    });
  }
  var contentCarousel = $('.content-text-carousel__slick');
  if (!contentCarousel) {
  } else {
    $.each(contentCarousel, function(index, value) {
        $(this).slick({
          slidesToShow: 1,
          infinite: false,
          prevArrow:
            `<button type="button" class="slick-arrow slick-prev">previous</button>`,
          nextArrow:
            `<button type="button" class="slick-arrow slick-next">next</button>`,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  centerPadding: "20px",
                  centerMode: true,
                  arrows: false,
                  infinite: false
                }
              }
            ]
        })
    });
  }

  //GLAVA-419
  const mediaQueryDesk = window.matchMedia('(min-width: 991px)')
  var carouselArticleList = $('.article-list__wrapper')

  if (!carouselArticleList ) {
  } else if (!mediaQueryDesk.matches) {
    $.each(carouselArticleList , function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          appendArrows: $(this).next(),
          prevArrow:
          `<button type="button" class="slick-arrow slick-prev">Previous</button>`,
          nextArrow:
          `<button type="button" class="slick-arrow slick-next">Next</button>`,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                infinite: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                swipe: true,
                swipeToSlide: true,
                slidesToShow: 1,
              }
            }
          ]
        })
    });
  }
  $(window).on("orientationchange",function(){
    if (!carouselArticleList ) {
    } else if (mediaQueryDesk.matches) {
      $.each(carouselArticleList , function(index, value) {
          $(this).not('.slick-initialized').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            appendArrows: $(this).next(),
            prevArrow:
            `<button type="button" class="slick-arrow slick-prev">Previous</button>`,
            nextArrow:
            `<button type="button" class="slick-arrow slick-next">Next</button>`,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  infinite: false
                }
              },
              {
                breakpoint: 768,
                settings: {
                  swipe: true,
                  swipeToSlide: true,
                  slidesToShow: 1,
                }
              }
            ]
          })
      });
    } else {
      $.each(carouselArticleList , function(index, value) {
        $(this).slick('unslick');
    });
    }
  });
  //GLAVA-411
  var carouselVideos = $('.video-carousel__slick')

  if (!carouselVideos ) {
  } else {
    $.each(carouselVideos , function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          prevArrow:
          `<button type="button" class="slick-arrow slick-prev">Previous</button>`,
          nextArrow:
          `<button type="button" class="slick-arrow slick-next">Next</button>`,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                infinite: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                swipe: true,
                swipeToSlide: true,
                slidesToShow: 1
              }
            }
          ]
        })
    });
  }

  var contentCarousel = $('.content-text-carousel__slick');

  if (!contentCarousel) {
  } else {
    $.each(contentCarousel, function(index, value) {
        $(this).not('.slick-initialized').slick({
          slidesToShow: 1,
          infinite: false,
          prevArrow:
            `<button type="button" class="slick-arrow slick-prev">previous</button>`,
          nextArrow:
            `<button type="button" class="slick-arrow slick-next">next</button>`,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  centerPadding: "20px",
                  centerMode: true,
                  arrows: false,
                  infinite: false
                }
              }
            ]
        })
    });
  }

})

